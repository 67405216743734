var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.titleDialog,
        visible: _vm.dialogVisible,
        width: "800px",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c("div", { staticClass: "contents" }, [
        _c("div", [
          _vm._v(
            " " +
              _vm._s(_vm.detail.type == 3 ? "退款单号" : "售后单号") +
              "：" +
              _vm._s(_vm.refundNo || "-") +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "tableWrap" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingData,
                    expression: "loadingData",
                  },
                ],
                attrs: {
                  data: _vm.tableData,
                  height: "100%",
                  border: true,
                  "header-cell-style": {
                    color: "#333333",
                    background: "#EFF6FF",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    align: "center",
                    width: "100",
                    label: "序号",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "skuName",
                    align: "center",
                    label: _vm.detail.type == 3 ? "缺货商品" : "售后商品",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "aftersalesNum",
                    align: "center",
                    label: _vm.detail.type == 3 ? "缺货件数" : "售后件数",
                  },
                }),
                _vm.detail.type == 4
                  ? _c("el-table-column", {
                      attrs: {
                        prop: "reason",
                        align: "center",
                        label: "售后原因",
                      },
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: {
                    prop: "refundAmount",
                    align: "center",
                    label: "退款金额(¥)",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tool.format_money(scope.row.refundAmount)
                              ) +
                              "元 "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }