var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: { type: "index", align: "center", width: "100", label: "序号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "userName",
          align: "center",
          width: "100",
          label: "用户名",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "userMobil",
          align: "center",
          label: "手机号",
          width: "160",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logisticBusinessId",
          align: "center",
          label: "集配中心",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logisticsName",
          align: "center",
          label: "城市仓",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "deliveryName",
          align: "center",
          label: "自提点名称",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "expenseType",
          align: "center",
          label: "收支类型",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "amount",
          align: "center",
          label: "金额(¥)",
          width: "120",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "balanceAmount",
          align: "center",
          label: "账户余额(¥)",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "recordType",
          align: "center",
          label: "类型明细",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "orderNo",
          align: "center",
          label: "订单号",
          "min-width": "120",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "payTime",
          align: "center",
          label: "支付时间",
          "min-width": "150",
        },
      }),
      _c("el-table-column", {
        attrs: { label: "操作", width: "160", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.type == 1
                  ? _c(
                      "router-link",
                      {
                        staticClass: "main-color pointer text-none",
                        attrs: {
                          to: {
                            name: "orderDetail",
                            query: { id: scope.row.businessId },
                          },
                        },
                      },
                      [_vm._v("查看 ")]
                    )
                  : _vm._e(),
                scope.row.type == 3 || scope.row.type == 4
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleRefund(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }