<template>
  <section class="corporate-recharge-detail">
    <Filtersearch
      ref="Filtersearchref"
      :list="Filtersearchlist"
      :fromdata="formData"
      @Refresh="onHandleRefresh"
      @search="onHandleSearch"
      @selectChange="cityChange"
    >
      <template v-slot:right>
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </template>
    </Filtersearch>

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        @onHandleRefund="onHandleRefund"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        type="primary"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 退款弹窗 start -->
    <refund-dialog ref="refundDialog"></refund-dialog>
    <!-- 退款弹窗 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import TableList from "./modules/table-list/index.vue";
import refundDialog from "./modules/refund-dialog/index.vue";
import { downLoadFlowBlob } from "@/utils/tools/base";
import {
  postMemberGetCustomerExpenseRecord,
  postMembergetCustomerExpenseRecordeExport,
} from "@/api/member/index";
import Filtersearch from "@/components/Filtersearch.vue";
import { INCOME_TYPE, TYPE_DETAILS } from "./utils/enum/index";

export default {
  name: "corporateRechargeDetail",
  components: {
    TableList,
    refundDialog,
    Filtersearch,
  },
  data() {
    return {
      Filtersearchlist: [
        {
          type: "select",
          name: "集配中心",
          clearable: true,
          model: "businessLogisticsId",
          placeholder: "请选择集配中心",
          selectoptionname: "business_list",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "selectChange",
          name: "城市仓",
          clearable: true,
          filterable: true,
          model: "tdcId",
          placeholder: "请选择城市仓",
          selectoptionname: "cityStoreList",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "select",
          name: "自提点",
          clearable: true,
          filterable: true,
          model: "deliveryId",
          placeholder: "请选择自提点",
          selectoptionname: "selflist",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "input",
          name: "用户名",
          clearable: true,
          model: "memberName",
          placeholder: "请输入用户名",
        },
        {
          type: "input",
          name: "手机号",
          clearable: true,
          model: "memberPhone",
          placeholder: "请输入用户名",
        },
        {
          type: "select",
          name: "收支类型",
          clearable: true,
          model: "expenseType",
          placeholder: "请选择收支类型",
          label: "label",
          value: "value",
          selectoption: INCOME_TYPE,
        },
        {
          type: "select",
          name: "类型明细",
          clearable: true,
          model: "recordType",
          placeholder: "请选择类型明细",
          label: "label",
          value: "value",
          selectoption: TYPE_DETAILS,
        },
        {
          type: "input",
          name: "订单号",
          clearable: true,
          model: "orderNo",
          placeholder: "请输入订单号",
        },
      ],
      loadingData: false,
      loadingSelfLogisticsList: false,
      disabledExport: false,
      logisticList: [], // 集配中心列表
      cityStoreList: [], // 城市仓列表
      formData: {
        businessLogisticsId: "", // 集配中心id
        tdcId: "", // 城市仓id
        deliveryId: "",
        memberName: "",
        memberPhone: "",
        orderNo: "",
        expenseType: "",
        recordType: "",
        page: 1,
        pageSize: 50,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
      selflist: [],
    };
  },
  watch: {},
  mounted() {
    this.onInitData();
  },
  methods: {
    cityChange(val) {
      this.Filtersearchlist.forEach((el) => {
        if (
          el.type == "select" &&
          el.selectoptionname &&
          el.selectoptionname == "selflist"
        ) {
          el.selectoption = [];
        }
      });
      this.$refs.Filtersearchref.from.deliveryId = "";
      // this.formData.deliveryId = "1";
      this.getListData(val);
    },
    getListData(val) {
      this.$api.general
        .deliveryList({
          logistics_id: val, //必须填写
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.selflist = res.data.data;
          if (res.data.data.length > 0) {
            this.Filtersearchlist.forEach((el) => {
              if (
                el.type == "select" &&
                el.selectoptionname &&
                el.selectoptionname == "selflist"
              ) {
                el.selectoption = [{ id: "", name: "全部" }, ...res.data.data];
              }
            });
          }
        });
    },
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
      this.ajaxGetLogisticList();
      this.ajaxGetCityStoreList();
    },
    /**
     * 获取集配中心列表
     */
    async ajaxGetLogisticList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticList = data;
        this.Filtersearchlist.forEach((el) => {
          if (
            el.type == "select" &&
            el.selectoptionname &&
            el.selectoptionname == "business_list"
          ) {
            el.selectoption = [{ id: "", name: "全部" }, ...data];
          }
        });
      } catch (err) {
        console.log("ajax ajaxGetLogisticList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    async ajaxGetCityStoreList() {
      // this.loadingSelfLogisticsList = true;
      const params = {
        name: "",
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        this.Filtersearchlist.forEach((el) => {
          if (
            el.type == "selectChange" &&
            el.selectoptionname &&
            el.selectoptionname == "cityStoreList"
          ) {
            el.selectoption = [{ id: "", name: "全部" }, ...data.data];
          }
        });
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        // this.loadingSelfLogisticsList = false;
      }
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      const params = { ...this.formData };
      try {
        const { data } = await postMemberGetCustomerExpenseRecord(params);
        const { total, data: list } = data;
        this.total = total;
        this.tableData = list;
      } catch (err) {
        console.log("ajax postMemberGetCustomerExpenseRecord err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 查询表单
     */
    onHandleSearch(e) {
      this.formData = e;
      this.formData.page = 1;
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.ajaxGetTableData();
    },
    /**
     * 导出数据
     */ async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postMembergetCustomerExpenseRecordeExport(query);
        this.goExportCenter();
      } catch (err) {
        this.disabledExport = false;
        console.log("ajax postMembergetCustomerExpenseRecordeExport err", err);
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
    /**
     * 退款弹窗
     */
    onHandleRefund(row) {
      this.$refs.refundDialog.onInitData(row);
    },
  },
};
</script>

<style lang="scss" scoped>
.corporate-recharge-detail {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
