<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    @close="onHandleClose"
    width="800px"
    class="dialog"
    center
  >
    <div class="contents">
      <div>
        {{ detail.type == 3 ? "退款单号" : "售后单号" }}：{{ refundNo || "-" }}
      </div>
      <div class="tableWrap">
        <el-table
          v-loading="loadingData"
          :data="tableData"
          height="100%"
          :border="true"
          :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        >
          <el-table-column type="index" align="center" width="100" label="序号">
          </el-table-column>
          <el-table-column
            prop="skuName"
            align="center"
            :label="detail.type == 3 ? '缺货商品' : '售后商品'"
          >
          </el-table-column>
          <el-table-column
            prop="aftersalesNum"
            align="center"
            :label="detail.type == 3 ? '缺货件数' : '售后件数'"
          >
          </el-table-column>
          <el-table-column
            prop="reason"
            align="center"
            label="售后原因"
            v-if="detail.type == 4"
          >
          </el-table-column>
          <el-table-column
            prop="refundAmount"
            align="center"
            label="退款金额(¥)"
          >
            <template slot-scope="scope">
              {{ tool.format_money(scope.row.refundAmount) }}元
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { postMemberGetCustomerRefundInfo } from "@/api/member/index.js";
export default {
  name: "refundDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      titleDialog: "",
      tableData: [],
      detail: {},
      loadingData: false,
      refundNo: "",
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.detail = data;
      this.titleDialog = data.recordType;
      this.tableData = [];
      this.loadingData = false;
      this.postAjaxMemberGetCustomerRefundInfo();
    },
    async postAjaxMemberGetCustomerRefundInfo() {
      this.loadingData = true;
      try {
        const res = await postMemberGetCustomerRefundInfo({
          refundId: this.detail.businessId,
        });
        this.tableData = res.data.refundInfo || [];
        this.refundNo = res.data.refundNo || "-";
        console.log(res, "postMemberGetCustomerRefundInfo");
      } catch (error) {
        console.log(error, "postMemberGetCustomerRefundInfo");
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding: 20px 30px;
  }
  .tableWrap {
    max-height: 300px;
    margin: 10px 0px;
    overflow: auto;
  }
}
</style>
