<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column type="index" align="center" width="100" label="序号">
    </el-table-column>
    <el-table-column
      prop="userName"
      align="center"
      width="100"
      label="用户名"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="userMobil" align="center" label="手机号" width="160">
    </el-table-column>
    <el-table-column
      prop="logisticBusinessId"
      align="center"
      label="集配中心"
      min-width="100"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="logisticsName"
      align="center"
      label="城市仓"
      min-width="100"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="deliveryName"
      align="center"
      label="自提点名称"
      min-width="100"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="expenseType"
      align="center"
      label="收支类型"
      min-width="100"
    >
    </el-table-column>
    <el-table-column prop="amount" align="center" label="金额(¥)" width="120">
    </el-table-column>
    <el-table-column
      prop="balanceAmount"
      align="center"
      label="账户余额(¥)"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="recordType"
      align="center"
      label="类型明细"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="orderNo"
      align="center"
      label="订单号"
      min-width="120"
    >
    </el-table-column>
    <el-table-column
      prop="payTime"
      align="center"
      label="支付时间"
      min-width="150"
    >
    </el-table-column>
    <el-table-column label="操作" width="160" align="center">
      <template slot-scope="scope">
        <router-link
          v-if="scope.row.type == 1"
          :to="{ name: 'orderDetail', query: { id: scope.row.businessId } }"
          class="main-color pointer text-none"
          >查看
        </router-link>
        <el-button
          type="text"
          @click="onHandleRefund(scope.row)"
          v-if="scope.row.type == 3 || scope.row.type == 4"
          >查看</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 退款弹窗
     */
    onHandleRefund(row) {
      this.$emit("onHandleRefund", row);
    },
  },
};
</script>

<style lang="scss" scoped></style>
