// 收支类型
export const INCOME_TYPE = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "支出",
    value: "1",
  },
  {
    label: "收入",
    value: "2",
  },
];

// 类型明细
export const TYPE_DETAILS = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "订单支付",
    value: "1",
  },
  {
    label: "取消订单",
    value: "2",
  },
  {
    label: "缺货退款",
    value: "3",
  },
  {
    label: "售后退款",
    value: "4",
  },
  {
    label: "退差价",
    value: "5",
  },
  {
    label: "供应商二次售后退款",
    value: "6",
  },
  {
    label: "城市仓二次售后退款",
    value: "7",
  },
  {
    label: "总控取消订单退款",
    value: "8",
  },
];
